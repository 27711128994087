// extracted by mini-css-extract-plugin
export const headerContainer = "header-module--headerContainer--33zwT";
export const titleContainer = "header-module--titleContainer--1-D8y";
export const logo = "header-module--logo--3DCRu";
export const title = "header-module--title--2w4ou";
export const navMenu = "header-module--navMenu--1KOdQ";
export const navItem = "header-module--navItem--2y73r";
export const langSelectorContainer = "header-module--langSelectorContainer--2DQix";
export const langSelectorButton = "header-module--langSelectorButton--343Sv";
export const langSelectorDropdown = "header-module--langSelectorDropdown--1Sh58";
export const active = "header-module--active--1eWJ2";
export const mobileNavMenuContainer = "header-module--mobileNavMenuContainer--2_kOa";
export const menuIcon = "header-module--menuIcon--2SPCm";
export const mobileNavMenu = "header-module--mobileNavMenu--1FHnt";
export const menuCloseIcon = "header-module--menuCloseIcon--wYrTK";
export const mobileNavLangSelector = "header-module--mobileNavLangSelector--pkHHA";